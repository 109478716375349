import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';
import { GtmDataTrackEventObject, GTMAction, GtmPageViewObject, GTMAppTypes, GtmAdvanceTrackingObject } from '../models/gtm-models/gtm';
import { GTMdata } from '../models/enum';
import { Data } from '../models/data';
import { GlobalSettings } from 'src/app/global.settings';
import { IConstants } from '../models/constants';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  window: any;
  constructor(windowRefService: WindowRefService, private data: Data, private constants: IConstants,
    public gtmDataTrackEventObject: GtmDataTrackEventObject, public cookieService: SsrCookieService,
    public gtmPageViewObject: GtmPageViewObject) {
    this.window = windowRefService.nativeWindow;
  }

  triggerDataTrackEvent(gtmObject: GtmDataTrackEventObject) {
    gtmObject.event = GTMAction.DataTrack;
    if (this.data && this.data.aceToken && this.data.aceToken.MEEUserId !== '' && !gtmObject.userId) {
      gtmObject.userId = this.data.aceToken.MEEUserId;
    }
    this.triggerEvent(gtmObject);
  }

  triggerPageViewEvent(gtmObject: GtmPageViewObject) {
    let userRole: string = '';
    let profileId: string = '';

    gtmObject.event = GTMAction.PageView;
    gtmObject.pageDomain = window.location.host;
    gtmObject.pagePath = window.location.pathname;
    gtmObject.pageTitle = window.document.title;
    gtmObject.language = window.navigator.language;
    gtmObject.country = this.data.countryCode;

    if (this.data.aceToken && this.data.aceToken.Role && this.data.aceToken.MEEUserId) {
      if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.INSTRUCTOR.toLocaleLowerCase()) {
        userRole = GTMdata.teacherRole;
      }
      else if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.studentApp.toLocaleLowerCase()) {
        userRole = GTMdata.studentRole;
      }
      profileId = this.data.aceToken.MEEUserId;
    }
    else {
      userRole = GTMdata.undefined;
      profileId = GTMdata.undefined;
    }

    gtmObject["content.category.platform"] = this.getPlatformType();
    gtmObject['user.segment.category'] = userRole;
    gtmObject["user.profile.profileId"] = profileId;
    gtmObject["content.category.courseName"] = this.data.selectedCourse?.courseName ? this.data.selectedCourse?.courseName : '';
    gtmObject["content.category.courseLevel"] = this.data.selectedCourse?.courseLevel ? this.data.selectedCourse?.courseLevel : '';
    gtmObject["content.category.bookName"] = this.data.selectedBook?.bookName ? this.data.selectedBook?.bookName : '';
    gtmObject["content.category.courseUnit"] = gtmObject["content.category.courseUnit"] ? gtmObject["content.category.courseUnit"] : '';
    gtmObject["content.category.lesson"] = gtmObject["content.category.lesson"] ? gtmObject["content.category.lesson"] : '';
    this.triggerPageView(gtmObject);
  }

  private triggerEvent(gtmObject: GtmDataTrackEventObject) {
    this.dataLayerPush(gtmObject);
  }

  private triggerPageView(gtmObject: GtmPageViewObject) {
    this.dataLayerPush(undefined, gtmObject);
  }

  private getPlatformType() {
    if (GlobalSettings.isBrowser) {
      return GTMAppTypes.Browser;
    }
    else if (GlobalSettings.isNative) {
      return GTMAppTypes.Native;
    }
    else {
      return GTMAppTypes.Desktop;
    }
  }

  private dataLayerPush(gtmDataTrackObject?: GtmDataTrackEventObject, gtmPageViewObject?: GtmPageViewObject) {
    let gtmObject: any = {};
    if (gtmDataTrackObject) {
      gtmObject = Object.assign({}, gtmDataTrackObject);
    }
    if (gtmPageViewObject) {
      gtmObject = Object.assign({}, gtmPageViewObject);
    }
    if (this.window && this.window.dataLayer && this.cookieService.get('isTrackingAllowed') === 'true' &&  this.window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      this.window.dataLayer.push(gtmObject);
    }
  }

  eventTracking(eventCategory: string, eventAction: string, eventLabel?: string) {
    this.gtmDataTrackEventObject = new GtmDataTrackEventObject();
    this.gtmDataTrackEventObject.eventCategory = eventCategory;
    this.gtmDataTrackEventObject.eventAction = eventAction;
    this.gtmDataTrackEventObject.eventLabel = eventLabel;
    this.triggerDataTrackEvent(this.gtmDataTrackEventObject);
  }

  pageViewTracking(pageCategory: string) {
    this.gtmPageViewObject = new GtmPageViewObject();
    this.gtmPageViewObject.pageCategory = pageCategory;
    this.triggerPageViewEvent(this.gtmPageViewObject);
  }

  advanceTracking(eventCategory: string, eventLabel: string, trackingParams: any) {
    this.gtmDataTrackEventObject = new GtmAdvanceTrackingObject();
    this.gtmDataTrackEventObject.eventCategory = eventCategory;
    this.gtmDataTrackEventObject.eventLabel = eventLabel;
    this.triggerAdvanceTrackEvent(trackingParams, eventLabel, eventCategory);
  }

  triggerAdvanceTrackEvent(trackingParams:any, eventLabel:any, eventCategory:any) {
    const gtmObj = {
      event: eventLabel,
      event_category: eventCategory,
      course_name: this.data?.selectedCourse?.courseName?this.data?.selectedCourse?.courseName: this.data?.courseName,
      course_level: this.data?.selectedCourse?.courseLevel? this.data?.selectedCourse?.courseLevel: this.data?.courseLevel,
      ...trackingParams,
      user_id: this.data?.aceToken?.MEEUserId || null,
      user_role: this.getUserRole(),
      }
    this.triggerAdvanceTracking(gtmObj);
  }

  private triggerAdvanceTracking(gtmAdvanceTrackObject: GtmPageViewObject) {
    let gtmObject: any = {};
    if (gtmObject) {
      gtmObject = Object.assign({}, gtmAdvanceTrackObject);
    }
    if (this.window && this.window.dataLayer && this.cookieService.get('isTrackingAllowed') === 'true' &&  this.window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      this.window.dataLayer.push(gtmObject);
    }
  }

  getUserRole() {
    let userRole = null;
    if (this.data.aceToken && this.data.aceToken.Role) {
      if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.INSTRUCTOR.toLocaleLowerCase()) {
        userRole = GTMdata.teacherRole;
      }
      else if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.studentApp.toLocaleLowerCase()) {
        userRole = GTMdata.studentRole;
      } 
      else if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.SUPER_ADMIN.toLocaleLowerCase()) {
        userRole = GTMdata.superAdmin;
      } 
    }
    return userRole;
  }
}

