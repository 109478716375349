import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Renderer2,
  PLATFORM_ID,
  Inject,
  NgZone,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { GlobalSettings } from 'src/app/global.settings';
import { Data } from './core/models/data';
import { ToastService } from './services/toast.service';
import { delay, filter, first, of, Subject, Subscription, takeUntil, takeWhile } from 'rxjs';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { UtilityService } from './services/utility.service';
import { AccessibilityService } from './core/services/accessibility.service';
import {
  downloadAppText,
  globalAccessibility,
  meePlatformOSEnvId,
  getOSType,
  applicationRole,
  GTMdata,
  EVENT_CATEGORY,
  MICROSERVICEJSON
} from 'src/app/core/models/enum';
import { LanguageExtService } from './services/language-ext.service';
import { environment } from 'src/environments/environment';
import * as compareVersions from 'compare-versions';
import { ApiConstants } from './core/constant/api.constant';
import { CdnAssets, IConstants } from './core/models/constants';
import { WindowRefService } from './core/services/window-ref.service';
import { ToastType } from '@maced/angular-components/lib/toast/toast.model';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AccountService } from './core/services/account.service';
import { PendoService } from './login/pendo.service';
import { GtmService } from './core/services/gtm.service';
import { BookInteractions, GTMEvents, Settings, VIEW_COURSE_CONTENT } from './core/models/gtm-models/gtm';
import { ReplaceWithPipe } from './features/notifications/pipes/replace-with.pipe';
import { MicroServiceService } from './services/micro-service.service';
const { detect } = require('detect-browser');
const browser = detect();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLogIn = false;
  message: string = '';
  msgType!: ToastType;
  currentRoute: any;
  versionNumber: string = '';
  updateType: number;
  destroyNotifier: Subject<any> = new Subject();
  skipNavigationPath: any = '#';
  skipNavigationLabel: string = globalAccessibility.SKIP_NAVIGATION_LABEL;
  selectedLanguage: string = 'en';
  languageFileName: string = 'meeLanguageTranslation';
  menuToggleFileName: string = 'functionality_show_hide';
  showOfflineMode = false;
  public errorPageOption: any = {};
  isShowHotCorner: boolean = false;
  public moduleClass: string = '';
  bodyClass: any = '';
  isConfirmed: boolean = false;
  dialogTitle: string = downloadAppText.TITLE? downloadAppText.TITLE : '';
  subTitle: string = downloadAppText.SUB_TITLE? downloadAppText.SUB_TITLE : '';
  btnLabelOne: string = downloadAppText.REMINDME_BTN_LABEL ? downloadAppText.REMINDME_BTN_LABEL : '';
  btnLabelTwo: string = downloadAppText.UPDATE_BTN_LABEL ? downloadAppText.UPDATE_BTN_LABEL : '';
  btnIdOne: string = downloadAppText.BTNID_ONE;
  btnIdTwo: string = downloadAppText.BTNID_TWO;
  meePlatformAppOS: string = '';
  mobileAndroideUrl = environment.appDownloadAndroidUrl;
  mobileIosUrl = environment.appDownloadIosUrl;
  isVcFreezBackground: boolean = false;
  dialogProps: any = {};
  subscriptions: Subscription[] = [];
  window: any;
  applicationRoleAriaLabel = applicationRole.aria_label;
  lastInternetStatus: boolean = true;
  showFooter: boolean = true;
  showPoupupMsg: boolean  = false;
  showEmbeddedDialog: boolean  = false;
  isJS:boolean = true;
  showNoInternetDialog: boolean = false;
  noInternetComponent: string = '';
  noInternetMessage: string = '';
  isLogoutScreen: boolean = false;
  killLogoutSubject: Subject<any> = new Subject();
  killLogoutForNative: boolean = false;
  
  //GN

  title = 'Welcome';
  backUrl = '/button';
  logoUrl = '/';
  logo = '../../assets/default/images/logo-mee-black-mac.svg';
  smallLogo = '../../assets/default/images/logo.svg';
  logoAltText = 'Macmillan Education Everywhere Platform';
  moreMenuTitle = 'More';
  ariaLabels: any = {
    backButtonAriaLabel: 'Back',
    closeButtonAriaLabel: 'Close',
    hamburgerAriaLabel: 'Menu',
    logoIcon: 'Logo',
  };
  activeHamburger = false as boolean;
  currentUrl: string = './';
  buttonType = 'button' as string;
  buttonId = 'hamburgerIcon' as string;
  buttonValue = 'hamburger' as string;
  isShowCascadebar = false;
  isShowMenubar = false;
  showMenu = true;
  isCascadeOpened = false;
  subActive = true;
  fcmCurrentToken = '' as string;
  public notificationEventRefernce: any;
  isDialogOpen: boolean = false;
  displayLiveSRonlyData: any = '';
  globalNavRequired: boolean = false;
  setLanguagehasExecuted:boolean = false;
  cascadeThemeOption = false as boolean
  userSelectedTheme: any;
  lastUrl: string | null = null;
    // GN end
  constructor(
    public toastService: ToastService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public data: Data,
    public _cd: ChangeDetectorRef,
    public utilityService: UtilityService,
    private accessibilityService: AccessibilityService,
    private languageExtService: LanguageExtService,
    public commonService: CommonService,
    public constants: IConstants,
    public cookieService: SsrCookieService,
    private _renderer: Renderer2,
    public windowRefService: WindowRefService,
    private activRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: Object,
    private ngZone: NgZone,
    private accountService: AccountService,
    private pendoService: PendoService,
    public gtmService: GtmService,
    public microServiceService: MicroServiceService
  ) {
    this.window = windowRefService.nativeWindow;
    if(!isPlatformBrowser(this.platformId)) {
      this.isJS = false;
    } else {
      this.isJS = true;
    }

    this.setLanguagehasExecuted = false;
    if (GlobalSettings.isBrowser) {
      this.smallLogo = environment.assetsCdnUrl + CdnAssets.logoCdn;
      this.logo = environment.assetsCdnUrl + CdnAssets.blackLogoCdn;
    }
    if (!isPlatformBrowser(this.platformId)) {
      this.globalNavRequired = true;
    }
    this.notificationEventRefernce = this.notificationEvent.bind(this);
    if (window.addEventListener) {
      window.addEventListener(
        'notificationEvent',
        this.notificationEventRefernce.bind(this),
        false
      );
    } else {
      (<any>window).addEventListener(
        'notificationEvent',
        this.notificationEventRefernce.bind(this)
      );
    }

    //GN

    if (isPlatformBrowser(this.platformId)) {
      const windowWidth = window?.innerWidth;
      if (windowWidth > 1023) {
        this.showMenu = true;
        this.isShowMenubar = true;
      }
    }
    this.router.events
      .pipe(takeWhile(() => this.subActive))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (isPlatformBrowser(this.platformId)) {
          const windowWidth = window?.innerWidth;
          if (windowWidth < 1024) {
            this.showMenu = false;
            this.isShowMenubar = false;
          }
          if(!event.url.includes('showAnswerKey=') && !event.url.includes('noPnd=') && !this.data.isOfflineUser && this.window._DATADOG_SYNTHETICS_BROWSER === undefined ) {
            try {this.pendoService.loadPendo();} catch(error){}
          }
          this.trackPageviewEvent(event.url);
        } else {
          const param = this.activRoute.snapshot.queryParams;
          const hierarchyLevel = param?.menuHierarchy?.split('_') || [];
          if (param?.menuHierarchy && hierarchyLevel.length > 0) {
            this.isCascadeOpened = true;
          } else {
            this.isCascadeOpened = false;
          }
        }
      });

    //GN END
    this.window = windowRefService.nativeWindow;
    this.subscriptions.push(
      this.toastService.currentMessage.subscribe((currentMessageObject) => {
        this.msgType = currentMessageObject.type;
        if (currentMessageObject.message !== '') {
          this.message = currentMessageObject.message;
          this._cd.detectChanges();
        }
      })
    );

    // Adding class for hamburger
    this?.subscriptions?.push(
      this.data?.loadGlobalNavidation?.subscribe((value: any) => {
        if (value === 'hamburger') {
          document.body.classList.add('one');
        } else {
          document.body.classList.remove('one');
        }
        this._cd.detectChanges();
      })
    );

    this.subscriptions.push(
      this.data?.isShowLanguageMenuBar?.subscribe((value: any) => {
        if (value === 'languagemenu') {
          document.body.classList.add('language-menu');
        } else {
          document.body.classList.remove('language-menu');
        }
        this._cd.detectChanges();
      })
    );

    this.subscriptions.push(
      this.accessibilityService.navigationUrlNotifier$
        .pipe(takeUntil(this.destroyNotifier))
        .subscribe((url) => {
          this.skipNavigationPath = url;
          this._cd.detectChanges();
        })
    );

    this.subscriptions.push(
      this.languageExtService?.languageLoadNotifier
        ?.pipe(takeUntil(this.destroyNotifier))
        ?.subscribe((status: boolean) => {
          this.skipNavigationLabel =
            this.data?.languageJson?.global_accessibility
              ?.skip_navigation_label ||
            globalAccessibility.SKIP_NAVIGATION_LABEL;
          this._cd.detectChanges();
        })
    );
    this.checkForObv();
    if (GlobalSettings.isNative) {
      this.data.isInNative = true;
      getDeviceTokenFromMobileCallback((res: any) => {
        this.data.deviceRootPath = JSON.parse(res).deviceRootPath;
        this.data.deviceType = JSON.parse(res).deviceType;
        this.data.deviceOSType = JSON.parse(res).deviceOS;
      });
    }
    if (GlobalSettings.isNative || GlobalSettings.isDesktop) {
      // Below statement is for Mobile devices language loading
      this.loadAllLanguagesInDevice();
    } else {
      this.loadMenuconfigJson();
    }
    if (isPlatformBrowser(this.platformId)) {
      this._renderer.listen('body', 'mousedown', () => {
        this._renderer.removeClass(document.body, 'using-keyboard');
        this._renderer.addClass(document.body, 'using-mouse');
      });
      this._renderer.listen('body', 'keydown', () => {
        this._renderer.removeClass(document.body, 'using-mouse');
        this._renderer.addClass(document.body, 'using-keyboard');
      });
    }
  }

  notificationEvent: any = (event: any) => {
    if (event.detail.notificationDetails.data.click_action) {
      this.commonService.redirectUrl(event.detail.notificationDetails.data.click_action);
    }
  };
  cascadeOpened($event: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      const windowWidth = window?.innerWidth;
      if (windowWidth > 1023) {
        this.isCascadeOpened = $event;
        this.isShowMenubar = $event;
        this.showMenu = $event;
      }
    }
  }

  cascadeThemeOpened(event: boolean) {
    const hTMLElement = document.querySelector('body')
    if (this.isMobileWidth() && event === true) {
      this.cascadeThemeOption = event;
      if (hTMLElement && !hTMLElement.classList.contains('nav-cascade-without-header')) {
        hTMLElement.classList.add('nav-cascade-without-header')
      }
    } else {
      this.cascadeThemeOption = false;
      if (hTMLElement && hTMLElement.classList.contains('nav-cascade-without-header')) {
        hTMLElement.classList.remove('nav-cascade-without-header')
      }
    }
  }

  isMobileWidth() {
    if (isPlatformBrowser(this.platformId)) {
      return window?.innerWidth < 1023
    }
    else {
      return false;
    }
  }

  ngOnInit(): void {
    this.handleLogoutSession();
    this.trackPageChange();
    this.setLanguagehasExecuted = false;
    if (this.cookieService.get('isKMLI_ssr') == 'true') {
      this.globalNavRequired = true;
    }
    this.data.srDisplayData?.pipe(takeUntil(this.destroyNotifier)).subscribe(val => {
      this.displayLiveSRonlyData = val;
      this._cd.detectChanges();
    });
    if (GlobalSettings.isNative) {
      this.utilityService.getDeviceFontUrl();
    }
   
    this.subscriptions.push(
      this.data.contentUpdateDailogue?.subscribe((res: any) => {
        if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
          this.isUserLogIn = res;
          this._cd.detectChanges();
        }
      })
    );
    this.findOS();
    if (!GlobalSettings.isBrowser) {
      this.utilityService.checkInternetConnectivity().pipe(first()).subscribe((isInternetAvailable) => {
        if (isInternetAvailable && !this.data.isOfflineUser) {
          this.getApplicationBuildVersion();
        }
      })
    }

    this.subscriptions.push(
      this.commonService.globalDekstopOfflineModePopUp$.subscribe(
        (request: any) => {
          if (typeof request === 'object') {
            this.showOfflineMode = !!request.flag;
            this.errorPageOption = request;
          } else {
            this.showOfflineMode = !!request;
            this.errorPageOption = { source: 'eReader', flag: !!request };
          }
          this._cd.detectChanges();
        }
      )
    );

    if (GlobalSettings.isNative) {
      this.data.isInNative = true;
      const variables = {
        MACXClientId: GlobalSettings.MACXClientId,
      };
      saveAngularVariablesToMobileDevice(variables, (data: any) => {
        /* No call back logic is required */
      });
    }

    this.currentRoute = '';
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          const currentUrl = event.url.split('?')[0];
          //  footer component conditional render
          if (currentUrl === '/login' || currentUrl === '/download' || currentUrl === '/' || currentUrl === '/logout' || currentUrl === '' || currentUrl === '/#page__content' || (GlobalSettings.isBrowser && !this.data.isAuthenticated && this.cookieService.get('isKMLI_ssr') !== 'true')) {
            this.showFooter = true;
            this.moduleClass = 'page--login';
          } else {
            this.showFooter = false;
          }
          if (
            currentUrl != '' &&
            currentUrl != '/' &&
            currentUrl != '/logout' &&
            !this.currentRoute.includes('globalHeaderId') &&
            currentUrl != 'null' &&
            currentUrl != '/api/login/logout' &&
            currentUrl !== '/download' &&
            currentUrl !== '/download#page__content'
          ) {
            if (
              this.activatedRoute.snapshot?.queryParams?.lang &&
              this.activatedRoute.snapshot?.queryParams?.langChanged &&
              this.data.aceToken?.MEEUserId
            ) {
              let langKey = 'langKey_' + this.data.aceToken?.MEEUserId;
              this.languageExtService
                .storLangKey(
                  langKey,
                  this.activatedRoute.snapshot?.queryParams?.lang
                )
                .pipe(takeUntil(this.destroyNotifier))
                .subscribe((event: any) => {
                  this.selectedLanguage =
                    this.activatedRoute.snapshot?.queryParams?.lang;
                  this.getLanguageData();
                });
            } else {
              this.checkKeyAndLoadLanguage();
            }
          } else {
            if (
              !this.currentRoute.includes('globalHeaderId') &&
              !this.currentRoute.includes('logout')
            ) {
              if (this.activatedRoute.snapshot?.queryParams.lang) {
                this.selectedLanguage =
                  this.activatedRoute.snapshot?.queryParams.lang;
              } else if (
                GlobalSettings.isBrowser &&
                this.cookieService.get('langKey')
              ) {
                this.selectedLanguage = this.cookieService.get('langKey');
              } else {
                this.selectedLanguage = GlobalSettings.defultLang;
              }
              if (!this.setLanguagehasExecuted) {
                this.languageExtService
                  .storLangKey(this.data.isOfflineUser ? 'offlineUserLangKey' : 'langKey', this.data.isOfflineUser ? this.data.offlineUserDefultLang : this.selectedLanguage)
                  .pipe(takeUntil(this.destroyNotifier))
                  .pipe(takeUntil(this.destroyNotifier))
                  .subscribe((event: any) => {
                    this.getLanguageData();
                  });
              }
            }
          }

          if (event.url.indexOf('/courses/courselist') > -1) {
            this.moduleClass = 'page--course-list';
            if (event.url.includes('showOnBoardingParam=true')) {
              this.moduleClass = 'page--course-list modal-open';
            }
          } else if (event.url.indexOf('/courses/course-details/') > -1) {
            this.moduleClass = 'course-details-page';
          } else if (event.url.indexOf('/notifications') > -1) {
            this.moduleClass = 'page--notification-list';
          } else if (event.url.indexOf('/extra-practices') > -1) {
            this.moduleClass = 'page--extra-practice';
          } else if (event.url.indexOf('/challenge') > -1) {
            this.moduleClass = 'page--challenge';
          } else if (event.url.indexOf('/homework-assessment') > -1) {
            this.moduleClass = 'page--assessment';
          } else if (
            event.url.indexOf('/school-work/grading/student-list') > -1
          ) {
            this.moduleClass = 'page--grading page--grading-student-list';
          } else if (event.url.indexOf('/school-work/grading') > -1) {
            if (event.url.includes('showFeedbackDialog=true') || event.url.includes('showBringbackDialog=true') || event.url.includes('js=false')) {
              this.moduleClass = 'page--grading modal-open';
            } else {
              this.moduleClass = 'page--grading';
            }
          } else if (event.url.indexOf('/school-work/progress') > -1) {
            if (event.url.includes('showFeedbackDialog=true') || event.url.includes('showBringbackDialog=true') || event.url.includes('js=false')) {
              this.moduleClass = 'page--progress-courses modal-open';
            } else {
              this.moduleClass = 'page--progress-courses';
            }
          } else if (
            event.url.indexOf('/school-work/calendar/add-event') > -1
          ) {
            this.moduleClass = 'page--add-calendar-event';
          } else if (
            event.url.indexOf('/school-work/calendar/calendar-event-detail') >
            -1
          ) {
            this.moduleClass = 'page--calendar-event-detail';
          } else if (
            event.url.indexOf('/school-work/calendar/add-attendees') > -1
          ) {
            this.moduleClass = 'page--add-attendees';
          } else if (
            event.url.indexOf('/school-work/calendar/review-attendees') > -1
          ) {
            if (
              (event.url.includes('removeAll=true') ||
                event.url.includes('isExit=true'))
            ) {
              this.moduleClass = 'page--review-attendees modal-open';
            } else {
              this.moduleClass = 'page--review-attendees';
            }
          } else if (event.url.indexOf('/school-work/calendar') > -1) {
            if (
              event.url.includes('openDeleteBox=true') &&
              !isPlatformBrowser(this.platformId)
            ) {
              this.moduleClass = 'page--calendar modal-open';
            } else {
              this.moduleClass = 'page--calendar';
            }
          } else if(event.url.indexOf('/course-builder') > -1) {
            if ((event.url.indexOf('/course-builder/create-activity-question') > -1) || (event.url.indexOf('/course-builder/review-questions') > -1)) {
              this.moduleClass = 'page--assessment';
            } else {
              this.moduleClass = 'page--course-builder';
            }
          } else if (event.url.indexOf('/offline-bookviewer') > -1) {
            if (event.url.includes('isExit=true')) {
              this.moduleClass = 'page--offline-bookviewer modal-open';
            } else {
              this.moduleClass = 'page--offline-bookviewer';
            }
          } else {
            this.moduleClass = '';
          }
          this._cd.detectChanges();

          if (
            currentUrl.includes('bookviewer') ||
            currentUrl.includes('add-event') ||
            currentUrl.includes('review-attendees') ||
            currentUrl.includes('add-attendees') ||
            currentUrl.includes('calendar-event-detail') ||
            currentUrl === '/login' ||
            currentUrl === '/' ||
            currentUrl === '/logout' ||
            currentUrl === '' ||
            currentUrl === '/#page__content' ||
            currentUrl.includes('extra-practices') ||
            currentUrl.includes('challenge') ||
            currentUrl.includes('grading/student-list') ||
            currentUrl.includes('progress/search-student') ||
            currentUrl.includes('grading/assessment-student-list') ||
            currentUrl.includes('grading/homework-student-list') ||
            currentUrl.includes('grading/grading-paper-assessment') ||
            currentUrl.includes('progress/coursework-details') ||
            currentUrl.includes('progress/student-coursework-details') ||
            currentUrl.includes('progress/assessment-details') ||
            currentUrl.includes('progress/attempt-history') ||
            currentUrl.includes('progress/teacher-student-details') ||
            currentUrl.includes('builder/summary') ||
            currentUrl.includes('builder/select-questions') ||
            currentUrl.includes('builder/assign-students') ||
            currentUrl.includes('builder/schedule-setting') ||
            currentUrl.includes('builder/student-review') ||
            currentUrl.includes('builder/select-activities') ||
            currentUrl.includes('builder/create-activity-question') ||
            currentUrl.includes('builder/select-tests') ||
            currentUrl.includes('builder/review-questions') ||
            currentUrl.includes('homework/viewStudents') ||
            currentUrl.includes('assessment/viewStudents') ||
            currentUrl.includes('course-builder/course-information') ||
            currentUrl.includes('course-builder/viewStudents') ||
            currentUrl.includes('course-builder/course-content') ||
            currentUrl.includes('course-builder/add-activities') ||
            currentUrl.includes('course-builder/student-dashboard/view-details') ||
            currentUrl.includes('course-builder/global-system-error') ||
            (currentUrl.includes('homework-assessment') && event.url.indexOf('redirectStudentUrl=true') > -1) ||
            (currentUrl.includes('/download') && !currentUrl.includes('/downloads')) || currentUrl.includes('homework-assessment-student/global-system-error')
          ) {
            if (
              currentUrl === '/login' ||
              currentUrl === '/' ||
              currentUrl === '/logout' ||
              currentUrl === '' ||
              currentUrl === '/#page__content'
            ) {
              this.moduleClass = 'page--login';
              this.globalNavRequired=false;
              this.data.loadMenuconfigAfterLogin.next(false);
            } else if (
              currentUrl === '/download' ||
              currentUrl === '/download#page__content'
            ) {
              this.moduleClass = 'download-page';
            }            
            if (currentUrl.includes('offline-bookviewer') && this.data.isOfflineUser) {
              this.data.isShowGlobalMenu.emit(currentUrl);
            } else {
              this.data.isShowGlobalMenu.emit('login');
            }
          } else if (currentUrl.includes('homework-assessment')) {
            if (event.url.includes('showAssessmentFormatDialog=true')  && !this.isJS) {
              this.moduleClass = 'page--assessment assessment-format-modal-open';
            } else {
              this.moduleClass = 'page--assessment';
            }
            this.data.isShowGlobalMenu.emit(currentUrl);
            this.data.loadMenuconfigAfterLogin.next(true);

          } else {
            this.data.isShowGlobalMenu.emit(currentUrl);
          }
        }
        this._cd.detectChanges();
      })
    );

    this.subscriptions.push(
      this.data?.bodyClass?.subscribe((value: any) => {
        this.bodyClass = value;
        this._cd.detectChanges();
      })
    );
    if (GlobalSettings.isNative) {
      let classNames = [];
      if (this.utilityService.checkDeviceOS()) {
        classNames.push('device-ios');
      } else {
        classNames.push('device-android');
      }
      let html = document.getElementsByTagName('html')[0];
      if (classNames.length) classNames.push('on-device');
      if (html.classList) html.classList.add.apply(html.classList, classNames);
    }
    this.activateRoute();
    this.addFocusToKMLIPopup();
    this.subscribeShowDialog();
    if (window.localStorage) {
      let isTrackAllowed = this.cookieService.get('isTrackingAllowed') || localStorage.getItem('isTrackingAllowed') || 'false';
      if (this.window && this.window.dataLayer) {
        this.window.dataLayer.push({ isTrackingAllowed: isTrackAllowed });
      }
    }
    this.updateVC_background();
    this.data.vcBackground?.emit(false);
    this.subscriptions.push(
      this.languageExtService?.langData$?.subscribe((data) => {
        if (data) {
          this.ngZone.run(() => {
            this.data.languageJson = data;
            this.setLables();
            this._cd.detectChanges();
          });
          this.languageExtService.languageLoadNotifier.emit(true);
          this._cd.detectChanges();
        }
      })
    );
    if (browser.name == "safari" && Notification.permission == "default") {
      this.showNotificationDialog(true);
    }
    this.showEmbeddedLink();
    this.showNoInternetDialogStatus();
    this.subscriptions.push(this.data.isUserSelectedThemeClass.subscribe((theme:any) => {
      if(theme) {
        this.userSelectedTheme = this.data.userSelectedThemeClass;
        this._cd.detectChanges();
      }
    }));
  }

  handleLogoutSession() {
    if (GlobalSettings.isNative) {
      window.addEventListener("message", this.handleMessage.bind(this));
    } else {
      this.data.isLogoutTrigger.pipe(takeUntil(this.killLogoutSubject)).subscribe((value) => {
        if (value) {
          this.killLogoutSubject.next(null);
          this.killLogoutSubject.complete();
          this.showLogoutScreen();
        }
      })
    }
  }

  handleMessage(event: MessageEvent) {
    if (event?.data?.systemError && event?.data?.systemError?.statusCode === 401) {
      if (!this.killLogoutForNative) this.showLogoutScreen();
      this.killLogoutForNative = true;
    }
  }

  showLogoutScreen() {
    this.data.isLoading.next(false);
    this.data.isLoadingLgoin.next(false);
    this.data.isLoadingKmli.next(false);
    this.data.isSessionExpire = true;
    this.isLogoutScreen = true;
    this._cd.detectChanges();
  }

  setLables() {
    if (this.data.languageJson) {
      switch (this.updateType) {
        case 0:
          this.dialogTitle = this.data.languageJson?.update_application?.free_upgrade;
          this.subTitle = this.data.languageJson?.update_application?.new_app_available;
            break;
        case 1:
          this.dialogTitle = this.data.languageJson?.update_application?.force_upgrade_label;
          this.subTitle = this.data.languageJson?.update_application?.force_app_subLabel;
            break;
        case 2:
          let languagePipe = new ReplaceWithPipe();
          this.dialogTitle = this.data.languageJson?.update_application?.close_upgrade_label;
          this.subTitle = languagePipe.transform(this.data.languageJson?.update_application?.close_app_subLabel, 'https://www.google.com');
           break;
      }
      this.btnLabelOne = this.data.languageJson?.update_application?.update_content?.remind_me_later;
      this.btnLabelTwo = this.data.languageJson?.update_application?.update_content?.update;
    }
  }

  checkForObv() {
    if (environment.isOBVApp) {
      this.data.isOfflineUser = true;
      this.accountService.setConfigurationForOfflineBookviewer();
    }
  }

  checkKeyAndLoadLanguage() {
    if (!this.data.aceToken?.MEEUserId && !this.data.isOfflineUser) {
      this.selectedLanguage = 'en';
      this.getLanguageData();
    } else {
      this.languageExtService
        .getLanguageKey(this.data.isOfflineUser ? 'offlineUserLangKey' : 'langKey_' + this.data.aceToken?.MEEUserId)
        .pipe(takeUntil(this.destroyNotifier))
        .pipe(takeUntil(this.destroyNotifier))
        .subscribe((userLangKeyResponse: any) => {
          if (userLangKeyResponse) {
            this.selectedLanguage = userLangKeyResponse;
            this.data.selectedLanguage = userLangKeyResponse;
            this.getLanguageData();
          } else {
            this.languageExtService
              .getLanguageKey('langKey')
              .pipe(takeUntil(this.destroyNotifier))
              .subscribe((defaultLangKeyResponse: any) => {
                this.languageExtService
                  .storLangKey(
                    'langKey_' + this.data.aceToken?.MEEUserId,
                    defaultLangKeyResponse
                  )
                  .pipe(takeUntil(this.destroyNotifier))
                  .subscribe((event: any) => {
                    this.selectedLanguage = defaultLangKeyResponse;
                    this.data.selectedLanguage = defaultLangKeyResponse;
                    this.getLanguageData();
                  });
              });
          }
        });
    }
  }
  updateVC_background() {
    this.subscriptions.push(
      this.data?.vcBackground?.subscribe((res: any) => {
        if (GlobalSettings.isBrowser || GlobalSettings.isDesktop) {
          this.isVcFreezBackground = res;
          this._cd.detectChanges();
        }
      })
    );
  }
  activateRouteForHotCorner() {
    this.subscriptions.push(
      this.commonService.hotcorner$.subscribe((res) => {
        this.isShowHotCorner = res;
      })
    );
  }

  addFocusToKMLIPopup() {
    setTimeout(() => {
      let kmlicheckboxinput: any = document.getElementById('dialog1');
      if (kmlicheckboxinput) {
        kmlicheckboxinput.querySelector('input')?.focus();
      }
    }, 2000);
  }

  activateRoute() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (
          params.toastId != undefined &&
          params.isOpen != undefined &&
          params.isOpen === 'false'
        ) {
          this.toastService.close();
        }
      })
    );
  }

  getApplicationBuildVersion() {
    this.subscriptions.push(
      this.utilityService.updateAvailable().subscribe((version) => {
        this.versionNumber = version.applicationBuildVersion;
        this.updateType = version.updateType;
        if(this.updateType){
          this.setLables();
        }
        if (!this.data.isUserLogIn) {
          this.checkAppVersion();
        }
      })
    );
  }
  loadAllLanguagesInDevice() {
    this.utilityService
      ?.checkInternetConnectivity()
      ?.subscribe((internetStatus) => {
        if (internetStatus && !this.data.isOfflineUser) {
          this.languageExtService.loadAllLanguagesInDevice(
            this.data.languageOptions.map((val: any) => val.value),
            (data: any) => {
              this.checkKeyAndLoadLanguage();
              this.loadMenuconfigJson();
              this.readMicroServiceJson();
            }
          );
        } else if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
          this.loadMenuconfigJson();
          this.readMicroServiceJson();
        }
      });

  }
  getLanguageData() {
    this.languageExtService.loadLanguageForPage(
      this.selectedLanguage,
      this.languageFileName
    );
  }

  getMenuToggleState() {
    this.utilityService
      .loadMenuToggleState(this.menuToggleFileName)
      .subscribe((data) => {
        if (data) {
          this.data.isShowGlobalMenu.emit('courses/courselist');
          this.data.menuToggleJson = data;
          this.data.menuToggleEnvObject =
            this.data.menuToggleJson[environment.name.toLowerCase()]?.[0];
          this.data.isMenuToggleSet.next(true);
          this.commonService.menuShowHide();
          this._cd.detectChanges();
        }
      });
  }

  checkAppVersion() {
    this.utilityService
      .checkInternetConnectivity()
      .subscribe((internetStatus) => {
        if (internetStatus) {
          if (environment.applicationBuildVersion !== '') {
            if (
              compareVersions(
                this.versionNumber,
                environment.applicationBuildVersion
              ) === 1 &&
              (GlobalSettings.isDesktop || GlobalSettings.isNative)
            ) {
              setTimeout(() => {
                this.isConfirmed = true;
                this._renderer.addClass(document.body, 'modal-open');
                this._cd.detectChanges();
              }, 2000);
            }
          }
        }
      });
  }

  dialogClicked(data: boolean) {
    this.isConfirmed = false;
    this._renderer.removeClass(document.body, 'modal-open');
    if (data && GlobalSettings.isDesktop) {
      let baseUrl = ApiConstants.aceApiBaseUrl;
      let downloadUrl: string =
        baseUrl +
        ApiConstants.downloadAppUrl +
        '?environmentId=' +
        this.meePlatformAppOS +
        '&appType=MEEPlatform';
      window.open(downloadUrl, '_self');
    } else if (data && GlobalSettings.isNative) {
      let bodyObj = {
        url:
          this.data.deviceOSType === getOSType.ios
            ? this.mobileIosUrl
            : this.mobileAndroideUrl,
        isExternal: true,
      };
      this._cd.detectChanges();
      this.commonService.openExternalLink(bodyObj);
    } else {
      if (GlobalSettings.isNative) {
        this._cd.detectChanges();
      }
    }
  }

  findOS() {
    if (navigator.userAgent.indexOf('Win') != -1) {
      this.meePlatformAppOS = meePlatformOSEnvId.meePlatformWin64_App;
    }
    if (navigator.userAgent.indexOf('Mac') != -1) {
      this.meePlatformAppOS = meePlatformOSEnvId.meePlatformMac_App;
    }
    if (
      navigator.userAgent.indexOf('Linux') != -1 &&
      !GlobalSettings.isNative
    ) {
      this.getLinuxUserType();
    }
  }

  getLinuxUserType() {
    this.subscriptions.push(
      this.utilityService.checkLinuxUserType().subscribe((res: any) => {
        if (res.path !== '') {
          let path = res.path;
          let result = path.includes('home');
          if (result) {
            this.meePlatformAppOS =
              meePlatformOSEnvId.meePlatformNonAdminLinux64_App;
          } else {
            this.meePlatformAppOS =
              meePlatformOSEnvId.meePlatformAdminLinux64_App;
          }
        }
      })
    );
  }

  subscribeShowDialog() {
    this.subscriptions.push(
      this.data.showConfirmationDialog?.subscribe((data: any) => {
        this.dialogProps.title = data.title;
        this.dialogProps.subTitle = data.subTitle;
        this.dialogProps.cancelLabel = data.cancelLabel;
        this.dialogProps.saveLabel = data.saveLabel;
        this.dialogProps.submitAction = '';
        this.dialogProps.visible = data.visible;
        this.dialogProps.event = data.event;
        this._cd.detectChanges();
      })
    );
  }
  showNotificationDialog(type: boolean) {
    this.isDialogOpen = type;
  }
  notification(): void {
    if (("Notification" in window) && Notification.permission == "default") {
      Notification.requestPermission().then((permission) => { });
    }
    this.isDialogOpen = false;
  }

  loadMenuconfigJson() {
    let hasExecuted = false;
    this.subscriptions.push(this.data.loadMenuconfigAfterLogin.pipe(takeUntil(this.destroyNotifier)).subscribe((res: boolean) => {
      if (res && !hasExecuted) {
        this.globalNavRequired = res;
        this._cd.detectChanges();
        this.getMenuToggleState();
        hasExecuted = true;

      }
    }));
  }

  readMicroServiceJson() {
    this.utilityService
      .loadMicroServiceJson(MICROSERVICEJSON.fileName)
      .subscribe((res) => {
        this.microServiceService.setMicroServiceFlag(res);
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.destroyNotifier.next(null);
    this.destroyNotifier.complete();
  }

  handleClickEvent() {
    if (this.data.aceToken) {
      let userPrefSnoozeTime;
      let stopSnoozeTime;
      if (this.cookieService.get('stopSnoozeTime')) {
        stopSnoozeTime = this.cookieService.get('stopSnoozeTime');
      }
      if (!GlobalSettings.isBrowser) {
        this.utilityService.getUserPreferenceFromDatabase().subscribe(object => {
          if ((object && object.length > 0) || Object.keys(object).length > 0) {
            let userPref;
            if (this.data.isInNative) {
              if (object.value) {
                let userPrefString = JSON.parse(object.value);
                if (userPrefString && Object.keys(userPrefString).length > 0) {
                  userPref = userPrefString;
                }
              }
            } else {
              userPref = JSON.parse(object[0]?.UserPreference);
            }
            if ((userPref && !userPref.isSnoozedTillLogout) || !userPref) {
              this.checkForPopUpMessage(stopSnoozeTime, false);
            } else {
              userPrefSnoozeTime = true;
              this.checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime);
            }
          } else {
            userPrefSnoozeTime = false;
            this.checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime);
          }
        });
      } else {
        if (this.cookieService.get('isSnoozedTillLogoutBrowser')) {
          let userPref = JSON.parse(this.cookieService.get('isSnoozedTillLogoutBrowser'));
          if (userPref) {
            userPrefSnoozeTime = true;
            this.checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime);
          } else {
            userPrefSnoozeTime = false;
            this.checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime);
          }
        } else {
          userPrefSnoozeTime = false;
          this.checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime);
        }
      }
    }

  }

  popupDisplayMsg() {
    this.showPoupupMsg = false;
    this.data.displayInternetMessage = "";
    this.data.displayCPUMessage = "";
    this.data.displayBothMessage = "";
    this.data.displayInternetMessageSubtext = "";
    this.data.displayCPUMessageSubtext = "";
    this.data.displayBothMessageSubtext = "";
    this.data.isSnoozedTillLogout = false;
    this.utilityService.getInternetSpeed().subscribe((res) => {
      this.data.internetSpeed = Number(Number(res.toString()).toFixed(3));

      if (this.data.isInNative) {
        this.setInternetMsg(this.data.internetSpeedThreshold);
      }
      else {
        this.setInternetMsg(this.data.internetSpeedThreshold);
        this.utilityService.getCpuUsage().subscribe((response) => {
          if (response.cpuPercentage > Number(this.data.cpuUsageThreshold)) {
            this.data.displayCPUMessage = this.constants.DISPLAY_CPU_MESSAGE;
            this.data.displayCPUMessageSubtext = this.constants.DISPLAY_CPU_SUB_MESSAGE;
            this.data.cpuUsage = response.cpuPercentage;
          }
          if (this.data.displayInternetMessage != '' && this.data.displayCPUMessage != '') {
            this.data.displayInternetMessage = "";
            this.data.displayCPUMessage = "";
            this.data.displayBothMessage = this.constants.DISPLAY_BOTH_MESSAGE;
            this.data.displayBothMessageSubtext = this.constants.DISPLAY_BOTH_SUB_MESSAGE;
          }
        });
      }

    })
  }

  setInternetMsg(internetSpeedThreshold) {
    if (this.data.internetSpeed > 0 && this.data.internetSpeed < Number(internetSpeedThreshold)) {
      this.showPoupupMsg = true;
      this.data.displayInternetMessage = this.constants.DISPLAY_INTERNET_MESSAGE;
      this.data.displayInternetMessageSubtext = this.constants.DISPLAY_INTERNET_SUB_MESSAGE;
      of('Observable To Update HTML').pipe(delay(500)).subscribe(() => {
        let slowInternetAlert = document.getElementById('slowInternetAlert');
        if (slowInternetAlert) {
          slowInternetAlert.classList.add('ease-in');
          slowInternetAlert.setAttribute("role", "alert");
          slowInternetAlert.setAttribute("aria-live", "aria-live");
          slowInternetAlert.focus();
        }
        if (document.getElementById('slowInternetAlertCloseButton')) {
          document.getElementById('slowInternetAlertCloseButton').addEventListener("click", this.snoozeTime.bind(this));
        }
      });
      this._cd.detectChanges();
    }
  }

  isSnoozed(event) {
    this.data.isSnoozedTillLogout = event.target.checked;
    let slowInternetAlert = document.getElementById('slowInternetAlert');
    if (slowInternetAlert) {
      slowInternetAlert.removeAttribute("role");
      slowInternetAlert.removeAttribute("aria-live");
    }
  }
  snoozeWithKMLI() {
    if (this.data.isSnoozedTillLogout && this.data.isKMLIChecked) {
      if (!GlobalSettings.isBrowser) {
        this.utilityService.getUserPreferenceFromDatabase().subscribe(object => {
          if ((object && object.length > 0) || Object.keys(object).length > 0) {
            let userPref;
            if (this.data.isInNative) {
              if (object.value && Object.keys(object.value).length > 0) {
                userPref = object.value;
              }
            } else {
              userPref = JSON.parse(object[0].UserPreference);
            }
            if ((userPref && !userPref.isSnoozedTillLogout) || !userPref) {
              if (userPref) {
                userPref['isSnoozedTillLogout'] = this.data.isSnoozedTillLogout;
              }
              else {
                userPref = {
                  isSnoozedTillLogout: this.data.isSnoozedTillLogout
                }
              }
            }
            this.utilityService.saveUserPreferenceToDatabase(JSON.stringify(userPref).replace(/[']/g, '\'\'')).subscribe(resp => {
              // This is intentional
            });
          } else {
            let userPref = {
              isSnoozedTillLogout: this.data.isSnoozedTillLogout
            }
            this.utilityService.saveUserPreferenceToDatabase(JSON.stringify(userPref).replace(/[']/g, '\'\'')).subscribe(resp => {
              // This is intentional
            });
          }
        });
      } else {
        this.cookieService.set('isSnoozedTillLogoutBrowser', 'true');
      }
    }
  }

  snoozeTime() {
    if (this.data.isSnoozedTillLogout) {
      this.snoozeWithKMLI();
    }

    let stopSnoozeTime = this.cookieService.get('stopSnoozeTime');
    if (!stopSnoozeTime) {
      this.data.stopSnoozeTime = false;
      this.cookieService.set('snoozeStartTime', new Date().getTime().toString());
    }
    this.closeToast();
  }

  checkForPopUpMessage(stopSnoozeTime, userPrefSnoozeTime) {
    let snoozeStartTime = this.cookieService.get('snoozeStartTime');
    if (this.data.enablePerformanceMeter && !stopSnoozeTime && !userPrefSnoozeTime) {
      if (snoozeStartTime && !this.data.stopSnoozeTime) {
        let val = 1000 * 60;
        let diff = Math.abs(Number(snoozeStartTime) - new Date().getTime()) / val;
        if (diff > this.data.snoozeTimeInMin) {
          this.popupDisplayMsg();
        }
      }
      else {
        this.data.stopSnoozeTime = true;
        this.popupDisplayMsg();
      }
    }
  }
  closeToast() {
    this.data.displayInternetMessage = "";
    this.data.displayCPUMessage = "";
    this.data.displayBothMessage = "";
    this.data.displayInternetMessageSubtext = "";
    this.data.displayCPUMessageSubtext = "";
    this.data.displayBothMessageSubtext = "";
    this.showPoupupMsg = false;
  }

  showEmbeddedLink() {
    this.subscriptions.push(this.data.isShowEmbeddedLinkViewer.subscribe((value => {
      this.showEmbeddedDialog = value.isShowEmbeddedLink;
    })));
  }
  showNoInternetDialogStatus() {
    this.subscriptions.push(this.data.isShowNoInternet.subscribe((value => {
      this.showNoInternetDialog = value.isShowNoInternetDialog;
      this.noInternetComponent = value.component;
      this.noInternetMessage = value.message;
      this._cd.detectChanges();
    })));
  }

  onCancel(event: any) {
    this.showNoInternetDialog = event;
    this.data.isShowNoInternet.next({ isShowNoInternetDialog: false, action: 'onCancel', component: this.noInternetComponent });
  }

  onTryAgain() {
    this.showNoInternetDialog = true;
    this.data.isShowNoInternet.next({ isShowNoInternetDialog: true, action: 'onTryAgain', component: this.noInternetComponent });
  }

  onSkipNavigationClick(event:any){
    this.data.isSkipNavigationExist = true;
  }

  trackPageviewEvent(url:any){
    if(!url?.includes('/bookviewer')) {
      const trackingParams = this.getTrackingParams(url);
      this.lastUrl = null;
      this.gtmService.advanceTracking(trackingParams.eventCategory,GTMEvents.PageView,trackingParams.params);
    }
  }

  getTrackingParams(url, pageNumber = '') {
    let userRole;
    let eventCategory;
    if (this.data.aceToken && this.data.aceToken.Role) {
      if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.INSTRUCTOR.toLocaleLowerCase()) {
        userRole = GTMdata.teacherRole;
        eventCategory = EVENT_CATEGORY.GlobalTeacher;
      }
      else if (this.data.aceToken.Role.toLocaleLowerCase() == this.constants.studentApp.toLocaleLowerCase()) {
        userRole = GTMdata.studentRole;
        eventCategory = EVENT_CATEGORY.GlobalStudent;
      }
    }
    let pageTitle
    if (url?.includes('/courselist') || url?.includes('/course-details')) {
      pageTitle = VIEW_COURSE_CONTENT
    } else if (url?.includes('/bookviewer')) {
      pageTitle = BookInteractions
    } else if (url?.includes('/languageSwitcher')) {
      pageTitle = Settings
    } else {
      pageTitle = window.document.title
    }
    return {
      eventCategory,
      params: {
        content_group: pageTitle,
        page_domain: window.location.host,
        page_path: window.location.pathname,
        page_title: window.document.title,
        user_language: this.data.selectedLanguage,
        country: this.data.countryCode,
        user_type: userRole,
        course_name: this.data.courseName,
        course_level: this.data.courseLevel,
        book_name: url?.includes('/bookviewer') ? this.data?.selectedBook?.bookName : '',
        page_number: url?.includes('/bookviewer') ? pageNumber : ''
      }
    };
  }

  trackPageChange() {
    let internalPageNumber = this.data.pageNumber;
    let _this = this;
    Object.defineProperty(_this.data, 'pageNumber', {
      get() {
        return internalPageNumber;
      },
      set(value: any) {
        if(internalPageNumber !== value) {
          internalPageNumber = value;
        }
        if (!_this.lastUrl && _this.router.url?.includes('/bookviewer')) {
          _this.lastUrl = _this.router.url;
          const trackingParams = _this.getTrackingParams(_this.router.url, value);
          _this.gtmService.advanceTracking(trackingParams.eventCategory, GTMEvents.PageView, trackingParams.params);// Call your function to handle the change
        }
      },
    });
  }
}